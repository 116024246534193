import React from 'react';
import { Helmet } from 'react-helmet';
import Contacts from './Contacts';

function About() {
  return (
    <>
      <Helmet>
        <title>Седам Маркет - Про нас</title>
      </Helmet>

      <section>
        <div className='section-header'>
          <h1>Sedam</h1>
          <h2>Чому саме ми?</h2>
        </div>

        <div className='about'>
          <div>
            <div className='about-img'>
              <img src='/img/tomato.jpg' alt='tomato' />
            </div>
            <div className='about-article'>
              <p>
                Останнім часом великого значення набувають роздрібні мережі, які
                забезпечують споживачам можливість швидко, зручно, з мінімальною
                втратою сил і часу придбати потрібні товари і послуги в умовах
                вільного вибору і широкого асортименту, недалеко від місця
                роботи або проживання, в потрібну годину та певної кількості.
              </p>
              <p>
                Все це можна сказати про роздрібну мережу магазинів{' '}
                <b>«Седам»</b>, яка складається з 16 магазинів саме в місті
                Чернігові, та трьох магазинів в районних центрах: містах
                Городня, Сновськ та смт. Ріпки.
              </p>
            </div>
          </div>
          <div>
            <div className='about-img'>
              <img src='/img/cart.png' alt='cart' />
            </div>
            <div className='about-article'>
              <p>
                На підприємстві працює близько <b>500 чоловік</b>.
              </p>
              <p>
                <b>«Седам»</b> пропонує Вам якісні продовольчі товари
                вітчизняного виробника та товари світових брендів.
              </p>
              <p>Ми пропонуємо також супутні товари, необхідні Вам в побуті.</p>
            </div>
          </div>
          <div>
            <div className='about-img round-border'>
              <img src='/img/bakery.jpg' alt='bakery' />
            </div>
            <div className='about-article'>
              <p>
                Наша особова пропозиція – <b>кулінарна продукція</b> та{' '}
                <b>кондитерські вироби</b> власного виробництва, яка
                користується великим попитом.
              </p>
            </div>
          </div>
          <div>
            <div className='about-img round-border'>
              <img src='/img/about.jpg' alt='bakery' />
            </div>
            <div className='about-article'>
              <p>
                Для посилення своїх позицій, утримання наявних та залучення
                нових клієнтів, збільшення товарообігу, підприємство
                використовує великий комплекс заходів, а саме: оптимізація
                асортименту, ефективне планування торговельних залів, покращення
                методів лояльності – для клієнтів, для посередників, для
                співробітників; удосконалення заходів матеріальної та моральної
                мотивації для співробітників.
              </p>
              <p>
                <b>«Седам»</b> запрошує до себе в компанію тих, хто бажає бути з
                нами в забезпеченні населення якісними товарами та послугами.
              </p>
            </div>
          </div>
        </div>
      </section>
    
      <Contacts /></>
  );
}

export default About;
